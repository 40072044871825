@import url("https://fonts.googleapis.com/css2?family=Jost:wght@200;300&display=swap");

/// COLORS
// Light scheme
$light: #fafafa;

// Dark scheme
$dark: #2a2a2a;

// Sizes
$primary-font-size: 16px;

// Breakpoints
$breakpoint: 600px;

// Line height
$short-line-height: 0.85;
$normal-line-height: 1;
$tall-line-height: 1.25;

// Spacing
$app_width: 1200px;
$app_height: 1000px;

// Opacity
$opacity-light: 0.25;

/// UTILITIES
.hidden {
  visibility: hidden;
}

/// GLOBAL
// Elements
#root {
  display: flex;
  font-family: "Jost", sans-serif;
  font-weight: 200;
  height: 100vh;
  width: 100vw;
}

html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $primary-font-size;
  font-weight: 100;
  color: $dark;
}

.hover-return {
  transition-duration: 1s;
  transition-property: transform, opacity;
}

.main-component {
  width: 100%;
}

.info-dropdown {
  display: flex;
  justify-content: center;
}

main {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  margin: auto;
  max-width: $app_width;
  max-height: $app_height;
  min-width: $app_width;
  padding: 0 20px;

  @media (max-width: $app_width) {
    min-width: inherit;
  }
}

.overlay {
  filter: blur(5px);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1000000;
}

.page {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.social-links {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 500px;
  min-width: fit-content;
  width: 100%;
  animation: flicker 120s infinite;

  a,
  div {
    animation: fade-in 3s;
    opacity: 0.65;

    &:hover {
      transform: scale(2);
      opacity: 1;
      transition: opacity 0.5s, transform 0.5s;
    }

    @media (prefers-color-scheme: dark) {
      color: $light;
    }
  }
}

.title {
  animation: flicker 30s infinite;
  color: $dark;
  font-size: 10em;
  font-weight: 300;
  line-height: $short-line-height;

  @media screen and (max-width: $breakpoint) {
    font-size: 4rem;
  }

  @media (prefers-color-scheme: dark) {
    color: $light;
  }
}

.subtitle {
  animation: flicker 12s infinite;
  color: $dark;
  font-size: 6em;
  margin: 20px 0;

  @media (prefers-color-scheme: dark) {
    color: $light;
  }

  @media screen and (max-width: $breakpoint) {
    font-size: 2rem;
  }
}

@media (prefers-color-scheme: dark) {
  #root {
    background: $dark;
  }
}

/// KEYFRAMES

@keyframes fade-in {
  0% {
    opacity: 60;
  }

  70% {
    opacity: 1;
  }

  90% {
    opacity: 0.85;
  }

  100% {
    opacity: 0.75;
  }
}

@keyframes flicker {
  0% {
    opacity: 1;
  }

  10% {
    opacity: 0.75;
  }

  30% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.75;
  }

  60% {
    opacity: 0.5;
  }

  80% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}
